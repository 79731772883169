import { createI18n } from "vue-i18n";

const messages = {
  en: {
    "10": "10",
    "25": "25",
    "50": "50",
    "100": "100",
    $vuetify: {
      open: "open",
      close: "close",
      noDataText: "No record found",
      layout: {
        menu: {
          dashboard: {
            title: "Home",
          },
          myCompanies: {
            title: "Companies",
          },
          cockpit: {
            title: "Cockpit",
          },
          configuration: {
            title: "Configuration",
          },
          dataroom: {
            title: "Dataroom",
          },
          profile: {
            title: "Profile",
          },
          logout: {
            title: "Logout",
          },
        },
      },
      "my-companies": {
        home: {
          title: {
            text: "Companies",
          },
          "card-header": {
            "search-input": {
              placeholder: "Search by Name or Tax Id...",
            },
            "filter-companies-btn": {
              text: "Filter Companies",
            },
            "generate-cockpit-btn": {
              text: "Generate Cockpit",
            },
          },
          table: {
            column: {
              name: {
                text: "Name",
              },
              "tax-id": {
                text: "Tax ID",
              },
              segment: {
                text: "Segment",
              },
              group: {
                text: "Group",
              },
              organization: {
                text: "Organization",
              },
              "last-alerts": {
                text: "Last alerts",
              },
              "updated-at": {
                text: "Updated at",
              },
              "actions": {
                text: "Actions",
              },
            },
          },
        },
      },
      cockpit: {
        tabs: {
          summary: {
            title: "Summary",
          },
          analysis: {
            title: "Analysis",
            cards: {
              accounting: {
                title: "Accounting",
                description:
                  "Analyze the Balance Sheet, P&L and other financial statements of the company",
              },
              financial: {
                title: "Financial",
                description:
                  "Analyze Cash Flow, Financial P&L, Burn Rate, Runway and other financial metrics",
              },
              comercial: {
                title: "Comercial",
                description:
                  "Check out the company's commercial metrics. Pipeline, Conversion rate, Churn Rate and other metrics",
              },
              operation: {
                title: "Key Performance Indicators",
                description:
                  "Metrics and KPIs of your company's operational activities",
              },
            },
          },
          alerts: {
            title: "Alerts",
          },
        },
        financial: {
          title: {
            text: "Financial Analysis",
          },
          tabs: {
            cashflow: {
              title: "Cash Flow",
            },
            'income-statement': {
              title: "Cash Flow",
            },
            'other-indicators': {
              title: "Other Indicators",
            },
            'expected-realized': {
              title: "Budget vs Actual"
            },
            'burn-rate': {
              title: "Burn Rate",
            },
            runaway: {
              title: "Runaway",
            },
            'cash-flow-table': {
              title: "Cash Flow Table",
              actions: {
                'from-to': {
                  text: "From-To"
                },
                'x-ray': {
                  text: "X-Ray"
                }
              },
              "cash-flow-tables": {
                title: {
                  operations: "Cash Flow from Operations",
                  financing: "Cash Flow from Financing",
                  investment: "Cash Flow from Investment",
                  total: "Total Cash Flow"
                },
                rows: {
                  'cash-in': "Cash in",
                  'cash-out': "Cash out",
                  'result': "Result",
                  'total-cash-flow': "Total Cash Flow"
                }
              },
              "from-to-table": {
                titles:{
                  'operations-cash-in': "Operations Cash In",
                  'operations-cash-out': "Operations Cash Out",
                  'operations-result': "Operations Result",
                  'financing-cash-in': "Financing Cash In",
                  'financing-cash-out': "Financing Cash In",
                  'financing-result': "Financing Result",
                  'investment-cash-int': "Investment Cash In",
                  'investment-cash-out': "Investment Cash Out",
                  'investment-result': "Investment Result",
                  'total-cash-flow': "Total Cash Flow",
                },
                cols: {
                  'category-identifier': "Account Number",
                  account: "Account",
                  'client-account': "Client Account",
                  type: "Type",
                  value: "Value"
                },
                rows: {
                  total: "Total"
                }
              }
            }
          }
        },
        indicators: {
          title: {
            text: "Key Performance Indicators",
          },
          tabs: {
            kpi: {
              title: "KPI X-Ray",
              header: {
                'create-btn': {
                  text: "Create Indicator for reference month",
                }
              },
              actions: {
                'x-ray': {
                  text: "X-Ray"
                },
                'edit': {
                  text: "Edit"
                },
                'delete': {
                  text: "Delete"
                },
              },
              "edit-form": {
                title: {
                  text: "Update Indicator for reference month"
                },
              },
              form: {
                title: {
                  text: "Create Indicator",
                },
                'redirect-indicator-configuration-btn': {
                  text: "Create new indicator"
                },
                fields: {
                  "reference-date": {
                    placeholder: "Referente Month"
                  },
                  "indicator": {
                    placeholder: "Indicator"
                  }
                },
                save: {
                  text: "Save"
                },
                cancel: {
                  text: "Cancel"
                }
              },
            },
            'kpi-table': {
              title: "KPIs Table",
            },
          }
        },
        accounting: {
          title: {
            text: "Accounting Analysis",
          },
        },
      },
      auth: {
        "sign-in": {
          needsEmailVerification: {
            text: "You need to verify your email",
          },
          email: {
            label: "Email",
          },
          password: {
            label: "Password",
          },
          "login-btn": {
            text: "Login",
          },
          "forgot-password-btn": {
            text: "Forgot Password",
          },
          "first-access-btn": {
            text: "First Access",
          },
          "remember-me-checkbox": {
            text: "Remember Me",
          },
          "wrong-credentials": {
            text: "Invalid email or password",
          }
        },
        "change-password": {
          "message": {
            title: "Reset Password",
            text: "Choose your new password",
          },
          password: {
            label: "Password",
          },
          confirmPassword: {
            label: "Confirm Password",
          },
          "password-mismatch-alert": {
            text: "Password does not match"
          },
          "confirm-btn": {
            text: "Confirm",
          },
        },
        "forgot-password": {
          message: {
            title: "Forgot your password?",
            text: "Fill your email and we will send a link to reset your password",
          },
          email: {
            label: "Email",
          },
          "email-sent-alert": {
            text: "Sent a link to reset your password"
          },
          "send-link-btn": {
            text: "Send Link",
          },
          "sign-in-btn": {
            text: "Sign In",
          },
        },
        "first-access": {
          message: {
            title: "First Access?",
            text: "Fill your email and we will send a link to define your password",
          },
          email: {
            label: "Email",
          },
          "email-sent-alert": {
            text: "Sent a link to define your password"
          },
          "send-link-btn": {
            text: "Send Link",
          },
          "sign-in-btn": {
            text: "Sign In",
          },
        },
      },
      configuration: {
        title: "Configuration",
        tabs: {
          modules: {
            title: "Modules",
            cards: {
              accounting: {
                title: "Accounting",
                description:
                  "Analyze the Balance Sheet, P&L and other financial statements of the company",
              },
              financial: {
                title: "Financial",
                description:
                  "Analyze Cash Flow, Financial P&L, Burn Rate, Runway and other financial metrics",
              },
              comercial: {
                title: "Comercial",
                description:
                  "Check out the company's commercial metrics. Pipeline, Conversion rate, Churn Rate and other metrics",
              },
              indicators: {
                title: "Key Performance Indicators",
                description:
                  "Metrics and KPIs of your company's operational activities",
              },
            },
          },
          alerts: {
            title: "Alerts"
          },
          users: {
            title: "Users",
            tabs: {
              'users-table': {
                header: {
                  'search-input': {
                    placeholder: 'Search by name or email'
                  }
                },
                table: {
                  column: {
                    'display-name': {
                      text: "Name"
                    },
                    email: {
                      text: "E-mail"
                    },
                    actions: {
                      text: "Actions"
                    }
                  }
                }
              }
            }
          },
        },
        indicators: {
          tabs: {
            'kpi-table-configuration': {
              title: "Tables",
              header: {
                'create-btn': {
                  text: "New KPI Table"
                },
                'search-input': {
                  placeholder: "Search by Name..."
                },
              },
              form: {
                "create-title": {
                  text: "Create KPIs Table"
                },
                "edit-title": {
                  text: "Edit KPIs Table"
                },
                fields: {
                  name: {
                    placeholder: "Name",
                    'required-message': "Name is required"
                  },
                  indicator: {
                    'required-message': "Type is required",
                    placeholder: "Indicator"
                  }
                },
                save: {
                  text: "Save"
                },
                cancel: {
                  text: "Cancel"
                }
              },
              delete: {
                "confirmation-text": "Confirm Delete?",
                text: "Delete"
              },
              table: {
                column: {
                  name: {
                    text: "Indicator"
                  },
                  format: {
                    text: "Type"
                  },
                  actions: {
                    text: "Actions"
                  },
                }
              }
            },
            'kpi-configuration': {
              title: "Indicators",
              header: {
                'create-btn': {
                  text: "New Indicator"
                },
                'search-input': {
                  placeholder: "Search by Name..."
                },
              },
              form: {
                "create-title": {
                  text: "Create Indicator"
                },
                "edit-title": {
                  text: "Edit Indicador"
                },
                fields: {
                  name: {
                    placeholder: "Name",
                    'required-message': "Name is required"
                  },
                  format: {
                    'required-message': "Type is required",
                    placeholder: "Type"
                  }
                },
                save: {
                  text: "Save"
                },
                cancel: {
                  text: "Cancel"
                }
              },
              table: {
                column: {
                  name: {
                    text: "Indicator"
                  },
                  format: {
                    text: "Type"
                  },
                  actions: {
                    text: "Actions"
                  },
                }
              }
            },
          }
        }
      },
      dataIterator: {
        rowsPerPageText: "Items per page:",
        pageText: "{0}-{1} of {2}",
      },
      dataFooter: {
        itemsPerPageText: "Items per page:",
        pageText: "{0}-{1} of {2}",
        itemsPerPageAll: "All",
        nextPage: "Next page",
        prevPage: "Previous page",
        lastPage: "Last page",
        firstPage: "First page",
      },
    },
  },
  pt_BR: {
    "10": "10",
    "25": "25",
    "50": "50",
    "100": "100",
    $vuetify: {
      open: "abrir",
      close: "fechar",
      noDataText: "Nenhum registro encontrado",
      layout: {
        menu: {
          dashboard: {
            title: "Home",
          },
          myCompanies: {
            title: "Empresas",
          },
          cockpit: {
            title: "Cockpit",
          },
          configuration: {
            title: "Configuração",
          },
          dataroom: {
            title: "Dataroom",
          },
          profile: {
            title: "Perfil",
          },
          logout: {
            title: "Logout",
          },
        },
      },
      "my-companies": {
        home: {
          title: {
            text: "Empresas",
          },
          "card-header": {
            "search-input": {
              placeholder: "Buscar por Nome ou CNPJ...",
            },
            "filter-companies-btn": {
              text: "Filtrar Empresas",
            },
            "generate-cockpit-btn": {
              text: "Gerar Cockpit",
            },
          },
          table: {
            column: {
              name: {
                text: "Nome",
              },
              "tax-id": {
                text: "CNPJ",
              },
              segment: {
                text: "Segmento",
              },
              group: {
                text: "Grupo",
              },
              organization: {
                text: "Organização",
              },
              "last-alerts": {
                text: "Últimos alertas",
              },
              "updated-at": {
                text: "Atualizado em",
              },
              "actions": {
                text: "Ações",
              },
            },
          },
        },
      },
      cockpit: {
        tabs: {
          summary: {
            title: "Resumo",
          },
          analysis: {
            title: "Análise",
            cards: {
              accounting: {
                title: "Contábil",
                description:
                  "Confira o Balanço Patrimonial, DRE e outras demonstrações contábeis da empresa",
              },
              financial: {
                title: "Financeiro",
                description:
                  "Faça a análise de Fluxo de Caixa, DRE Gerencial, Burn Rate, Runway e outras métricas financeiras",
              },
              comercial: {
                title: "Comercial",
                description:
                  "Confira as métricas comerciais da empresa. Pipeline completo, Taxa de conversão, Churn Rate e muito mais",
              },
              operation: {
                title: "Indicadores Chave de Performance",
                description:
                  "Controle as métricas e KPIs das atividades operacionais da sua empresa",
              },
            },
          },
          alerts: {
            title: "Alertas",
          },
        },
        financial: {
          title: {
            text: "Análise Financeira",
          },
          tabs: {
            cashflow: {
              title: "Fluxo de Caixa",
            },
            'income-statement': {
              title: "Fluxo de Caixa",
            },
            'other-indicators': {
              title: "Outros Indicadores",
            },
            'expected-realized': {
              title: "Orçado vs Realizado"
            },
            'burn-rate': {
              title: "Burn Rate",
            },
            runaway: {
              title: "Runaway",
            },
            'cash-flow-table': {
              title: "Tabela de Fluxo de caixa",
              actions: {
                'from-to': {
                  text: "De-Para"
                },
                'x-ray': {
                  text: "Raio-X"
                }
              },
              "cash-flow-tables": {
                title: {
                  operations: "Fluxo de Caixa Operacional",
                  financing: "Fluxo de Caixa de Financiamento",
                  investment: "Fluxo de Caixa de Investimento",
                  total: "Fluxo de Caixa Total"
                },
                rows: {
                  'cash-in': "Entradas",
                  'cash-out': "Saída",
                  'result': "Resultado",
                  'total-cash-flow': "Fluxo de Caixa Total"
                },
              },
              "from-to-table": {
                titles:{
                  'operations-cash-in': "Entradas Operacionais",
                  'operations-cash-out': "Saídas Operacionais",
                  'operations-result': "Resultado Operacional",
                  'financing-cash-in': "Entradas de Financiamento",
                  'financing-cash-out': "Saídas de Financiamento",
                  'financing-result': "Resultado de Financiamento",
                  'investment-cash-int': "Saídas de Investimento",
                  'investment-cash-out': "Saídas de Investimento",
                  'investment-result': "Resultado de Investimento",
                  'total-cash-flow': "Fluxo de Caixa Total",
                },
                cols: {
                  'category-identifier': "Número da Conta",
                  account: "Conta",
                  'client-account': "Conta do Cliente",
                  type: "Tipo",
                  value: "Valor"
                },
                rows: {
                  total: "Total"
                }
              }
            }
          }
        },
        indicators: {
          title: {
            text: "Indicadores Chave de Performance"
          },
          tabs: {
            kpi: {
              title: "Raio-X do KPI",
              header: {
                'create-btn': {
                  text: "Cadastrar Indicador de um Mês"
                }
              },
              actions: {
                'x-ray': {
                  text: "Raio-X"
                },
                'edit': {
                  text: "Editar"
                },
                'delete': {
                  text: "Deletar"
                },
              },
              "edit-form": {
                title: {
                  text: "Atualizar Indicador de um mês"
                },
              },
              form: {
                title: {
                  text: "Cadastro de indicador"
                },
                'redirect-indicator-configuration-btn': {
                  text: "Criar novo indicador"
                },
                fields: {
                  "reference-date": {
                    placeholder: "Mês de Referência"
                  },
                  "indicator": {
                    placeholder: "Indicador"
                  }
                },
                save: {
                  text: "Salvar"
                },
                cancel: {
                  text: "Cancelar"
                }
              }
            },
            'kpi-table': {
              title: "Tabela de KPIs",
            },
          }
        },
        accounting: {
          title: {
            text: "Análise Contábil",
          },
        },
      },
      auth: {
        "sign-in": {
          needsEmailVerification: {
            text: "Você precisa verificar o email",
          },
          email: {
            label: "Email",
          },
          password: {
            label: "Senha",
          },
          "login-btn": {
            text: "Entrar",
          },
          "forgot-password-btn": {
            text: "Esqueci a Senha",
          },
          "first-access-btn": {
            text: "Primeiro Acesso",
          },
          "remember-me-checkbox": {
            text: "Lembrar",
          },
          "wrong-credentials": {
            text: "E-mail ou senha inválidos",
          }
        },
        "change-password": {
          "message": {
            title: "Redefenir Senha",
            text: "Escolha sua nova senha abaixo",
          },
          password: {
            label: "Senha",
          },
          confirmPassword: {
            label: "Confirmar Senha",
          },
          "confirm-btn": {
            text: "Confirmar",
          },
        },
        "forgot-password": {
          message: {
            title: "Esqueceu sua senha?",
            text: "Informe seu endereço de email e enviaremos um link para redefinir sua senha",
          },
          email: {
            label: "Email",
          },
          "email-sent-alert": {
            text: "Link enviado por e-mail",
          },
          "send-link-btn": {
            text: "Enviar Link",
          },
          "sign-in-btn": {
            text: "Fazer Login",
          },
          "password-mismatch-alert": {
            text: "Senhas não são iguais"
          },
        },
        "first-access": {
          message: {
            title: "Primeiro Acesso?",
            text: "Informe seu endereço de email e enviaremos um link para redefinir sua senha",
          },
          email: {
            label: "Email",
          },
          "email-sent-alert": {
            text: "Link enviado por e-mail",
          },
          "send-link-btn": {
            text: "Enviar Link",
          },
          "sign-in-btn": {
            text: "Fazer Login",
          },
          "password-mismatch-alert": {
            text: "Senhas não são iguais"
          },
        },
      },
      configuration: {
        title: "Configuração",
        indicators: {
          tabs: {
            'kpi-table-configuration': {
              title: "Tabelas",
              header: {
                'create-btn': {
                  text: "Nova Tabela de Indicadores"
                },
                'search-input': {
                  placeholder: "Procurar por nome..."
                },
              },
              form: {
                "create-title": {
                  text: "Criar Tabela de Indicadores"
                },
                "edit-title": {
                  text: "Editar Tabela de Indicadores"
                },
                fields: {
                  name: {
                    placeholder: "Nome",
                    'required-message': "Nome é obrigatório"
                  },
                  indicator: {
                    placeholder: "Indicador"
                  }
                },
                save: {
                  text: "Salvar"
                },
                cancel: {
                  text: "Cancelar"
                }
              },
              delete: {
                "confirmation-text": "Confirmar Remoção?",
                text: "Apagar"
              },
              table: {
                column: {
                  name: {
                    text: "Indicador"
                  },
                  format: {
                    text: "Tipo"
                  },
                  actions: {
                    text: "Ações"
                  },
                }
              }
            },
            'kpi-configuration': {
              title: "Indicadores",
              header: {
                'create-btn': {
                  text: "Novo Indicador"
                },
                'search-input': {
                  placeholder: "Procurar por nome..."
                },
              },
              form: {
                "create-title": {
                  text: "Criar Indicador"
                },
                "edit-title": {
                  text: "Editar Indicador"
                },
                fields: {
                  name: {
                    placeholder: "Nome",
                    'required-message': "Nome é obrigatório"
                  },
                  format: {
                    'required-message': "Tipo é obrigatório",
                    placeholder: "Tipo"
                  }
                },
                save: {
                  text: "Salvar"
                },
                cancel: {
                  text: "Cancelar"
                }
              },
              table: {
                column: {
                  name: {
                    text: "Indicador"
                  },
                  format: {
                    text: "Tipo"
                  },
                  actions: {
                    text: "Ações"
                  },
                }
              }
            },
          }
        },
        tabs: {
          modules: {
            title: "Módulos",
            cards: {
              accounting: {
                title: "Contábil",
                description:
                  "Confira o Balanço Patrimonial, DRE e outras demonstrações contábeis da empresa",
              },
              financial: {
                title: "Financeiro",
                description:
                  "Faça a análise de Fluxo de Caixa, DRE Gerencial, Burn Rate, Runway e outras métricas financeiras",
              },
              comertial: {
                title: "Comercial",
                description:
                  "Confira as métricas comerciais da empresa. Pipeline completo, Taxa de conversão, Churn Rate e muito mais",
              },
              indicators: {
                title: "Indicadores Chave de Performance",
                description:
                  "Controle as métricas e KPIs das atividades operacionais da sua empresa",
              },
            },
          },
          alerts: {
            title: "Alertas"
          },
          users: {
            title: "Usuários",
            tabs: {
              'users-table': {
                header: {
                  'search-input': {
                    placeholder: 'Pesquisar por nome ou email'
                  }
                },
                table: {
                  column: {
                    'display-name': {
                      text: "Nome"
                    },
                    email: {
                      text: "E-mail"
                    },
                    actions: {
                      text: "Ações"
                    }
                  }
                }
              }
            }
          },
        }
      },
      dataIterator: {
        rowsPerPageText: "Element per sida:",
        pageText: "{0}-{1} de {2}",
      },
      dataFooter: {
        itemsPerPageText: "Itens por página:",
        pageText: "{0}-{1} de {2}",
        itemsPerPageAll: "Todas",
        nextPage: "Próxima Página",
        prevPage: "Página Anterior",
        lastPage: "Última Página",
        firstPage: "Primeira Página",
      },
    },
  },
};

// @ts-ignore
const i18n = new createI18n({
  legacy: false, // Vuetify does not support the legacy mode of vue-i18n
  locale: "en",
  fallbackLocale: "en",
  messages,
});
export default i18n;
